import React from 'react';

const ContactUsScreen: React.FC = () => {
  return <div className="content-container">
    <div className='about-us-container'>
        <iframe 
            src="https://forms.monday.com/forms/embed/8c3191b5b90da2991d3c34fbd2abd901?r=use1" 
            width="100%" 
            height="100%" 
            style={{ border: 0, boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)" }}
            title="Contact Us Form"
        ></iframe>
    </div>
  </div>
};

export default ContactUsScreen;