import { useLocation, useNavigate } from "react-router-dom";
import { Page } from "../common/Page";
import { createContext, useContext, useEffect, useState } from "react";
import { pages } from "../main/pages";

interface NavControllerContextType {
    page: Page;
    navigate: (page: Page) => void;
}

const NavControllerContext = createContext<NavControllerContextType | undefined>(undefined);

export const useNavController = (): NavControllerContextType => {
    const context = useContext(NavControllerContext);
    if (!context) throw new Error('useNavController must be used within a NavControllerProvider');
    return context;
}

interface NavControllerProviderProps {
    children: React.ReactNode;
}

export const NavControllerProvider = ({ children }: NavControllerProviderProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    // There should always be a page for corresponding route. If not then we redirect to the first page 
    // (which in `pages` is the HomePage)
    const page: Page | undefined = pages.find((page) => page.route === location.pathname);
    const startPage = page ? page : pages[0];
    const [currentPage, setCurrentPage] = useState<Page>(startPage);

    useEffect(() => {
        navigate(currentPage.route);
    }, [currentPage, navigate]);

    const value = {
        page: currentPage,
        navigate: setCurrentPage
    }

    return (
        <NavControllerContext.Provider value={value}>
            {children}
        </NavControllerContext.Provider>
    );
}