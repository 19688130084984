import AboutUsScreen from "../screens/AboutUsScreen"
import RemindMeProductScreen from "../screens/RemindMeProductScreen"
import { Page } from "../common/Page";
import { MenuItemsMap } from "../common/MenuItems";
import RemindMeDemoScreen from "../screens/RemindMeDemoScreen";
import ContactUsScreen from "../screens/ContactUsScreen";

// It should be home screen when we have multiple apps
export const HomePage: Page = { name: 'Home', route: '/', screen: RemindMeProductScreen }; 
export const AboutUsPage: Page = { name: 'About', route: '/about', screen: AboutUsScreen };
export const RemindMePage: Page = { name: 'Item Reminder!', route: '/products/remind-me', screen: RemindMeProductScreen };
export const RemindMeDemoPage: Page = { name: 'Item Reminder! Demo', route: '/resources/remind-me-demo', screen: RemindMeDemoScreen };
export const ContactUsPage: Page = { name: 'Contact Us', route: '/contact-us', screen: ContactUsScreen };

export const pages: Page[] = [
	HomePage,
	AboutUsPage,
	RemindMePage,
	RemindMeDemoPage,
	ContactUsPage
];

export const menuItems: MenuItemsMap = new Map(
	[
		[
			'About', 
			{
				name: "About",
				root: AboutUsPage,
				overflowItems: []
			}
		],
		[
			'Products', 
			{
				name: "Products",
				root: RemindMePage,
				overflowItems: [RemindMePage]
			}
		],
		[
			'Resources', 
			{
				name: "Resources",
				root: RemindMeDemoPage,
				overflowItems: []
			}
		]
	]
);