import React from 'react';

const AboutUsScreen: React.FC = () => {
  return <div className="content-container">
    <div className='about-us-container'>
      <h2>About Us</h2>
      <h3>Our Mission</h3>
      <p>Supporting entrepreneurs with reliable, effective, and secure software.</p>

      <h3>Who We Are</h3>
      <p>Our journey began in the heart of Silicon Valley, where I honed my skills in both startup environments and large multinational corporations. With a focus on mobile applications, particularly Android, I led teams that improved app stability and performance for millions of users. Handling sensitive financial and personal data has taught me the importance of security and reliability in software development.</p>

      <h3>Why We Care</h3>
      <p>After six years in the industry, I recognized a gap in the market: small and medium-sized businesses (SMBs) needed robust, affordable tools to streamline their operations without getting bogged down by technical complexities. This realization inspired me to create a company dedicated to helping SMBs thrive. As a solopreneur who has navigated the challenges of starting and running a business, I understand SMBs face unique challenges on a daily basis and we want to eliminate software from that equation. This personal experience fuels my commitment to offering white-glove service and practical solutions that make a real difference.</p>

      <h3>What We Do</h3>
      <p>We specialize in developing tools that expand feature sets, optimize performance, and centralize data by connecting different systems. Our primary platform, monday.com, serves as the foundation for our innovative solutions tailored to meet the unique needs of SMBs. By focusing on what truly matters to your business, we provide software that is both effective and budget-friendly, allowing you to concentrate on your strengths and what makes your business unique.</p>

      <h3>Our Promise</h3>
      <p>At the core of our business is a belief in personal connection and dedicated support. We're not just here to sell software; we're here to build relationships and solve problems. Whether you need help with monday.com or any other aspect of your business, we're ready to assist. With a network of domain expert industry contacts and a passion for problem-solving, we're committed to helping your business grow and succeed. Let's navigate the complexities of technology together, so you can focus on what you do best.</p>
    </div>
  </div>;
};

export default AboutUsScreen;
