import Navbar from "../components/NavBar"
import Footer from "../components/Footer"
import { Routes, Route } from "react-router-dom"
import React from "react"
import { NavControllerProvider } from "../navigation/navigation"
import { pages } from "../main/pages"

const LandingScreen: React.FC = () => {
    return (
        <>
            <NavControllerProvider>
                <Navbar />
                <Routes>
                    {pages.map((page) => (
                        <Route key={page.name} path={page.route} element={React.createElement(page.screen)} />
                    ))}
                </Routes>
                <Footer />
            </NavControllerProvider>
        </>
    )
}

export default LandingScreen;