import React, { useCallback } from 'react';
import { Toolbar, Button } from '@mui/material';
import { ContactUsPage, HomePage, menuItems } from '../main/pages';
import { Page } from '../common/Page';
import { MenuItemsMap } from '../common/MenuItems';
import "../styles/menu.css"
import { useNavController } from '../navigation/navigation';


const Navbar: React.FC = () => {
  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between', gap: 2 }}>
        <CompanyLogo />
        <MenuItems items={menuItems} />
        <ContactUsButton />
      </Toolbar>
      <div style={{ height: '1px', backgroundColor: 'white' }}></div>
    </>
  )
};

export default Navbar;

const ContactUsButton: React.FC = () => {
  const { navigate } = useNavController();
  return (
    <Button color="inherit" onClick={() => navigate(ContactUsPage)}>Contact Us</Button>
  )
}

const CompanyLogo: React.FC = () => {
  const { navigate } = useNavController();
  return (
    <div>
      <h2 style={{ flexGrow: 1, marginLeft: '20px', cursor: 'pointer' }} onClick={() => navigate(HomePage)}>Clever Extensions</h2>
    </div>
  )
}

const MenuItems: React.FC<{ items: MenuItemsMap }> = ({ items }) => {
  const { navigate } = useNavController();
  // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // const openMenu: (event: React.MouseEvent<HTMLElement>, item: MenuItemData) => void = useCallback((event: React.MouseEvent<HTMLElement>, item: MenuItemData) => {
  //   if (item.overflowItems.length === 0) return;
  //   setAnchorEl(event.currentTarget);
  // }, []);

  // const closeMenu = useCallback(() => {
  //   setAnchorEl(null);
  // }, []);

  const onMenuItemClicked = useCallback((page: Page) => {
    // closeMenu();
    navigate(page);
  }, [navigate]);

  // const hasMenuItems: (item: MenuItemData) => boolean = useCallback((item: MenuItemData) => {
  //   return item.overflowItems.length > 0;
  // }, []);

  return (
    <div className="menu-items">
      {Array.from(items.values()).map((item) => {
        return (
          <div key={item.root.route}>
            <Button
                sx = {{ marginRight: '20px' }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(event) => onMenuItemClicked(item.root)}>
                {item.name}
            </Button>
            {/* <Menu
                anchorEl={anchorEl}
                open={anchorEl != null && hasMenuItems(item)}
                onClose={closeMenu}
                MenuListProps={{ onMouseLeave: closeMenu }} >
                {Array.from(item.overflowItems).map((page: Page) => (
                  <MenuItem key={page.route} onClick={() => onMenuItemClicked(page)}>
                      {item.name}
                  </MenuItem>
                ))}
            </Menu> */}
          </div>
        )
      })}
    </div>
  )
}