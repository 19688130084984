import React from 'react';

const RemindMeDemoScreen: React.FC = () => {
  return <div className="content-container">
    <div className="demo-video-container">
        <iframe
            width="90%"
            height="90%"
            src="https://www.youtube.com/embed/JWb8z1TR76c"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    </div>
  </div>;
};

export default RemindMeDemoScreen;