// src/ThemeContext.tsx
import { createContext, useState, useMemo, FC, ReactNode } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';

interface ThemeContextProps {
  toggleTheme: () => void;
  currentTheme: 'light' | 'dark';
}

export const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

const ThemeProviderComponent: FC<{ children: ReactNode }> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<'light' | 'dark'>('dark');

  const muiTheme = useMemo(() => theme(currentTheme), [currentTheme]);

  const toggleTheme = () => {
    setCurrentTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, currentTheme }}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderComponent;
