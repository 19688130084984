// src/components/Footer.tsx
import React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';
import { useNavController } from '../navigation/navigation';
import { AboutUsPage, RemindMePage, RemindMeDemoPage } from '../main/pages';

const footerSections = {
  products: [RemindMePage],
  resources: [RemindMeDemoPage],
  company: [AboutUsPage],
};

const Footer: React.FC = () => {
  const { navigate } = useNavController();

  return (
    <>
      <div style={{ height: '1px', backgroundColor: 'white' }}></div>
      <Box component="footer" sx={{ p: 4, backgroundColor: 'background.paper' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>Products</Typography>
          {footerSections.products.map((page, index) => (
            <Typography key={index} variant="body2">
              <Link underline="none" color="inherit" className='linkStyle' onClick={() => navigate(page)}>{page.name}</Link>
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>Resources</Typography>
          {footerSections.resources.map((page, index) => (
            <Typography key={index} variant="body2">
              <Link underline="none" color="inherit" className='linkStyle' onClick={() => navigate(page)}>{page.name}</Link>
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>Company</Typography>
          {footerSections.company.map((page, index) => (
            <Typography key={index} variant="body2">
              <Link underline="none" color="inherit" className='linkStyle' onClick={() => navigate(page)}>{page.name}</Link>
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default Footer;
