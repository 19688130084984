import { createTheme } from '@mui/material/styles';
import "../styles/global.css"

const lightModeColors = {
	primary: {
	  main: '#1976d2',
	},
	secondary: {
	  main: '#dc004e',
	},
  }

const darkModeColors = {
	primary: {
	  main: '#90caf9',
	},
	secondary: {
	  main: '#f48fb1',
	},
  }

const getDesignTokens = (mode: 'light' | 'dark') => ({
  palette: { mode, ...(mode === 'light' ? lightModeColors : darkModeColors) },
  typography: {
	fontFamily: 'ShantellSans',
  }
});

const theme = (mode: 'light' | 'dark') => createTheme(getDesignTokens(mode));

export default theme;
