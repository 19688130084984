import React from 'react';
import remindMeIcon from '../assets/remind-me-icon.png';

const RemindMeProductScreen: React.FC = () => {
  return <div className="content-container">
    <div className='product-container'>
      <Header/>
      <DemoContainer />
    </div>
  </div>;
};

const Header: React.FC = () => {
  return <div className='header-container'>
    <div className='header-row-container'> 
      <img style={{ height: '40px', width: '40px'}} src={remindMeIcon} alt="logo" />
      <p style={{ marginLeft: "20px", fontSize: '30px' }}>Item Reminder!</p>
    </div>
    <p><b>Key Benefits and Use Cases</b></p>
    <p><b>Set Reminders with Contextual Notes</b></p>
    <p>Add reminders to any monday.com item along with notes to provide context. With reminders linked to your tasks and projects, you can prioritize your work effectively.</p> 
    <b>Seamless Integration with monday.com</b> 
    <p>Item Reminder! integrates flawlessly with your existing monday.com workflow. Create, update, and manage reminders without leaving the platform. It's as simple as selecting an item, adding a reminder time, and including any relevant notes.</p> 
    <a href="https://auth.monday.com/oauth2/authorize?client_id=4d5a4e266a603270d46064a8bfc056e8&response_type=install">
      <img
        alt="Add to monday.com"
        height="42"
        src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
      />
    </a>
  </div>;
}

const DemoContainer: React.FC = () => { 
  return <iframe
      width="800px"
      height="500px"
      src="https://www.youtube.com/embed/JWb8z1TR76c"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>;
}

export default RemindMeProductScreen;
