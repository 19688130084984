// src/App.tsx
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ThemeProviderComponent from './theme/ThemeContext';
import './styles/global.css';
import LandingScreen from './screens/LandingScreen';

const App: React.FC = () => {

  return (
    <ThemeProviderComponent>
      <Router>
        <LandingScreen />
      </Router>
    </ThemeProviderComponent>
  );
};

export default App;